import React from "react";
import {imgSrc} from "../helpers/CommonScripts";

function CustomCatalog(props) {
	return (
		<>
			{props.productDetail.catalogMessage && (
				props.displayText ?
			<div className="ecom-icon-default d-flex align-items-center color-highlight">
				<div className="ecom-pd-ctlg-icon ecom-icon-small-size ecom-icon-mr">
					<img src={imgSrc("/static/images/core-item.png")} className="ecom-icon" alt="..." />
				</div>
				<div className="ecom-icon-text" dangerouslySetInnerHTML={{ __html: props.productDetail.catalogMessage}}></div>
			</div>
					:
					<img src={imgSrc("/static/images/core-item.png")}
						 className="ecom-icon-small-size ml-1" alt="..."
						 title={props.productDetail.catalogMessage ? props.productDetail.catalogMessage : "Catalog Item"}/>
			)
			}
		</>
	)
}

export default CustomCatalog;