import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import { IoClose } from "react-icons/io5";
import { BsNutFill } from "react-icons/bs";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import useOnclickOutside from "react-cool-onclickoutside";
import GlobalSearch from "../../GlobalSearch";
import Menu from "../Menu";

function Navbar(props) {
  const label = props.labels;
  const [menuHeight, setMenuHeight] = useState(window.innerHeight - document.getElementsByClassName("fixed-top")[0]?.offsetHeight);
  const [menuClicked, setMenuClicked] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const removeSuggestions = () => {
    setShowSuggestions(false);
  };

  window.onresize = function () { setMenuHeight(window.innerHeight - document.getElementsByClassName("fixed-top")[0]?.offsetHeight) }

  useEffect(() => {
    setMenuHeight(window.innerHeight - document.getElementsByClassName("fixed-top")[0]?.offsetHeight)
  }, [])

  const onclickOutsideRef = useOnclickOutside(() => {
    setMenuClicked(false);
  });

  const popover = (
    <Popover style={{ maxWidth: 360}} className="m-0 ml-n2 pt-0 menuArrow rounded-0">
      <Popover.Content className="popover-content" style={{height: menuHeight+"px", overflowY: "scroll"}}>
        <Menu />
      </Popover.Content>
    </Popover>
  );
  return (
    <div className="d-flex bg-dark-blue">
      {props.closeBtn ? (
        <div className="p-3 mt-n1">
          <IoClose
            size="2em"
            color="white"
            onClick={removeSuggestions}
          />
        </div>
      ) : (
          <div className="mr-auto" ref={onclickOutsideRef}>
            <OverlayTrigger placement="bottom" rootClose  overlay={popover} trigger="click">
              <a className={`nav-link text-center text-white pb-0 pt-0-5 mr-1 mobMenu ${menuClicked ? "activeMenu pb-1" : ""}`} href="#" onClick={(e)=> {setMenuClicked(!menuClicked); e.preventDefault();}}>
                <BsNutFill size="2em" className={`nutIcon ${menuClicked ? "activeMenu" : ""}`} />
                <div className="p-0">
                  <span className="nav-link-sub">{label?.shop ?? "Shop"}</span>
                </div>
              </a>
            </OverlayTrigger>
          </div>
      )}
      <GlobalSearch
          setMenuClicked={setMenuClicked}
          showSuggestions={showSuggestions}
          setShowSuggestions={setShowSuggestions}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    labels: state.commonReducer.pageLabels,
  };
};

export default connect(mapStateToProps, null)(Navbar);
