import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import { postRequest } from "../apiCalls/ApiCall";
import useOnclickOutside from "react-cool-onclickoutside";
import {_objectWithoutProperties} from "../helpers/Helpers";
import { PRODUCT_SUGGESTIONS_URL } from '../constants/Constants';
import {Link, useHistory, useLocation} from 'react-router-dom';
import { setJessionId } from "../helpers/CommonScripts";

function GlobalSearch(props) {
  const label = props.labels;
  const [suggestions, setSuggestions] = useState([]);
  const [searchTerm, setSearchTerm] = useState(decodeURIComponent(localStorage.getItem("SEARCH_TERM") === null ? "" : localStorage.getItem("SEARCH_TERM")));
  const history = useHistory();
  const location = useLocation();
  const isMobileView = props.isMobileView;

  function handleChange(event) {
    const searchText = event.target.value;
    localStorage.removeItem("SEARCH_TERM");
    if (searchText.length <= 2) {
      props.setShowSuggestions(false);
    }
    if (searchText.trim().length > 2) {
      suggestionList(searchText);
      setTimeout(() => {props.setShowSuggestions(true)}, 250);
    } else {
      setSuggestions([]);
    }
    setSearchTerm(searchText);
  }

  const suggestionList = (text) => {
    postRequest(PRODUCT_SUGGESTIONS_URL, {"query":text}).then((res) => {
      if(res && res.suggestions && text.length > 2){
        setSuggestions(res.suggestions);
      }
    });
  };

  const removeSearchTerm = () => {
    setSearchTerm("");
    localStorage.removeItem("SEARCH_TERM");
  }

  const closeSuggestionsModal = () => {
    setTimeout(() => {props.setShowSuggestions(false)}, 250);
    setSuggestions([]);
  };
  const clearGlobalSearchInput = () => {
    removeSearchTerm();
    closeSuggestionsModal();
  };

  const onclickOutsideRef = useOnclickOutside(closeSuggestionsModal);

  const handleFocus = (event) => {
    if(window.innerWidth < 767) {
      event.preventDefault();
      return false
    }
  }

  const getProductSearchUri = () => {
    if (searchTerm && searchTerm.trim() !== "") {
      return `?query=${encodeURIComponent(searchTerm.trim())}&fsi=1`;
    } else {
      return "";
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if(searchTerm && searchTerm !== "") {
      localStorage.setItem("SEARCH_TERM", encodeURIComponent(searchTerm));
      closeSuggestionsModal();
      history.push(setJessionId(`/product${getProductSearchUri()}`));
    }
  };

  useEffect(() => {
    const pathname = location.pathname;
    const searchParams = location.search;
    if(localStorage.getItem("SEARCH_TERM") !== null) {
      if(searchTerm !== decodeURIComponent(localStorage.getItem("SEARCH_TERM"))) {
        removeSearchTerm();
      }
    } else {
      setSearchTerm("");
    }
    if(pathname.includes("product")) {
      if(pathname.includes("/all") || (!searchParams.includes("query") && searchParams.includes("fsi"))) {
        removeSearchTerm();
      }
    } else {
      removeSearchTerm();
    }
  }, [location.pathname, location.search])

  return(
    <>
      <form className={isMobileView ? "ml-auto w-100" : "w-75"} onClick={()=> {if(isMobileView) {props.setMenuClicked(false)}}}>
        <div className={`input-group ${isMobileView && "pt-mobieSearchbox"}`}>
          <input type="text" className="form-control rounded-0 ecom-search-input js-auto-suggest ie-nav-search-input ignore-onclickoutside border-0 shadow-none" id="suggestionQuery" autoComplete="off" onChange = {handleChange} onFocus={handleFocus} placeholder={label?.searchPlaceHolder} aria-label={label?.searchPlaceHolder} value={searchTerm}/>
          {searchTerm && (<i className="fa fa-times cursor-pointer bg-white d-flex align-items-center pr-2" onClick={clearGlobalSearchInput}></i>)}
          <div className="input-group-append">
            <button className={`btn btn-primary rounded-0 ${isMobileView && "mr-2"}`} onClick={handleSubmit} type="submit">
              <i className="fa fa-search"></i>
            </button>
          </div>
        </div>
      </form>

      { props.showSuggestions && suggestions.length > 0 &&
        <div  className={`feco-header-dropdown feco-auto-suggest border-top ${
            !isMobileView && "w-90"
        }`} tabIndex="0" style={
          isMobileView
              ? {
                display: "block",
                top: document.querySelector(".fixed-top")?.offsetHeight,
                minHeight: document.body?.offsetHeight,
              }
              : { display: "block" }} ref={onclickOutsideRef}>
          <ul className="nav flex-column">
            <li className="nav-item">
              <div className="product-suggester-wrap">
                <div>
                  <ul className="nav-list pl-0">
                    {
                      suggestions.map((suggestion, index) => {
                        return (
                          <>
                            <li>
                              <div className="product-suggester-group" key={index}>
                                <div className="product-suggester-group-name" dangerouslySetInnerHTML={{ __html: suggestion.title }}></div>
                              </div>

                            {
                              suggestion.items.map((item, ind) => {
                                {
                                  var item_value = _objectWithoutProperties(item, ["highlightedQuery", "filterName", "filterValue", "productFamiliyFilterName", "productFamiliyFilterValue"]);
                                  delete item_value.categoryId;
                                  var joined_array_val = Object.values(item_value).map(encodeURIComponent).join("/");
                                  var url = joined_array_val === '' ?  joined_array_val:`/${joined_array_val}`
                                }
                                return (
                                  <Link to={setJessionId(`/product${url}?fsi=1${item.filterName ? "&" + item.filterName + "=" + encodeURIComponent(item.filterValue) : ""}${item.productFamiliyFilterName ? "&" + item.productFamiliyFilterName + "=" + encodeURIComponent(item.productFamiliyFilterValue) : ""}${suggestion.title === "Brand / Manufacturer" ? "&" + "showAll="+ item.filterName : ""}${item.categoryId ? "&categoryId="+item.categoryId : ""}`)} className="nav-link" onClick={()=>{clearGlobalSearchInput()}} key={ind}>
                                    <ul className="nav-list pl-0">
                                      <li className="nav-item" key={ind}>
                                        <span dangerouslySetInnerHTML={{__html: item.highlightedQuery}}></span>
                                      </li>
                                    </ul>
                                  </Link>
                                )
                              })
                            }
                            </li>
                          </>
                        )
                      })
                    }
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      }
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    labels: state.commonReducer.pageLabels,
    isMobileView: state.commonReducer.isMobileView,
    closeBtn: state.commonReducer.closeSuggestions,
  }
};

export default connect( mapStateToProps, null )(GlobalSearch);